import React from 'react';
import './shop.css';
import { Link } from 'react-router-dom';  // Correct import for Link

import hospitalImg from '../../assets/shop/hospital-management.gif';
import hrImg from '../../assets/shop/hr-management.jpg';
import logisticsImg from '../../assets/shop/logistics.jpg';
import posImg from '../../assets/shop/pos-system.jpg';
import realEstateImg from '../../assets/shop/real-estate.jpg';
import reservationImg from '../../assets/shop/reservation.jpg';
import restaurantImg from '../../assets/shop/restaurant-management.jpg';
import retailImg from '../../assets/shop/retail-management.jpg';
import schoolImg from '../../assets/shop/school-management.jpg';
import warehouseImg from '../../assets/shop/warehouse-management.jpg';
import etimsImg from '../../assets/shop/etims-integration.jpg';
import customSoftwareImg from '../../assets/shop/custom-software.jpg';

import Footer from '../Footer/Footer'; // Assuming Footer is a separate component

const Shop = () => {
  const products = [
    {
      id: 1,
      title: 'Hospital Management System',
      description: 'A comprehensive hospital management system to streamline patient care and hospital operations.',
      imgSrc: hospitalImg,
      rating: 4, // 4 stars
    },
    {
      id: 2,
      title: 'HR Management System',
      description: 'A robust solution for managing human resources, payroll, and employee records.',
      imgSrc: hrImg,
      rating: 4,
    },
    {
      id: 3,
      title: 'Logistics Management System',
      description: 'Efficient tools for tracking and managing supply chain logistics.',
      imgSrc: logisticsImg,
      rating: 4,
    },
    {
      id: 4,
      title: 'POS System',
      description: 'An easy-to-use point of sale system for retail and restaurant businesses.',
      imgSrc: posImg,
      rating: 4,
    },
    {
      id: 5,
      title: 'Real Estate Management',
      description: 'A real estate management system for property listings, client management, and more.',
      imgSrc: realEstateImg,
      rating: 4,
    },
    {
      id: 6,
      title: 'Reservation System',
      description: 'A flexible reservation system for hotels, restaurants, and more.',
      imgSrc: reservationImg,
      rating: 4,
    },
    {
      id: 7,
      title: 'Restaurant Management',
      description: 'A comprehensive solution for managing restaurant orders, customers, and inventory.',
      imgSrc: restaurantImg,
      rating: 4,
    },
    {
      id: 8,
      title: 'Retail Management System',
      description: 'Complete retail management with sales, inventory, and customer management tools.',
      imgSrc: retailImg,
      rating: 4,
    },
    {
      id: 9,
      title: 'School Management System',
      description: 'Manage students, courses, grades, and more with an intuitive school management system.',
      imgSrc: schoolImg,
      rating: 4,
    },
    {
      id: 10,
      title: 'Warehouse Management',
      description: 'A warehouse management system to efficiently track and control inventory.',
      imgSrc: warehouseImg,
      rating: 4,
    },
    {
      id: 11,
      title: 'ETIMS Integration',
      description: 'Integrate with ETIMS for seamless educational management and record-keeping.',
      imgSrc: etimsImg,
      rating: 4,
    },
    {
      id: 12,
      title: 'Custom Software Development',
      description: 'Tailor-made software solutions to fit your business requirements and goals.',
      imgSrc: customSoftwareImg,
      rating: 4,
    },
  ];

  return (
    <div className="shop-container">
      <div className="row">
        {products.map((product) => (
          <div key={product.id} className="col-lg-6 col-md-6 mb-4">
            <div className="card">
              <img src={product.imgSrc} className="card-img-top product-img" alt={product.title} />
              <div className="card-body">
                <h5 className="card-title">{product.title}</h5>
                <p className="card-text">{product.description}</p>
                
                {/* Rating System */}
                <div className="rating">
                  {Array.from({ length: product.rating }).map((_, index) => (
                    <span key={index} className="star">★</span>
                  ))}
                </div>

                {/* Read More Button with Link to Contact Us */}
                <Link to="/contactUs" className="btn btn-link mt-2">Get Service</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      {/* Call the Footer Component */}
      <Footer />
    </div>
  );
};

export default Shop;
