import img1 from '../../../assets/aboutUs/05.jpeg';
import img2 from '../../../assets/aboutUs/11.jpeg';

const fakeData = [
    {
        id: 1,
        heading: 'Meyani Software Solutions',
        img: img1,
        content: 'At Meyani, we specialize in building custom software solutions tailored to your business needs. Whether you require a robust web application, mobile app, or enterprise software, our team of experts works closely with you to create powerful tools that enhance productivity and efficiency. With a focus on innovation and user experience, we turn ideas into scalable, high-quality software products.'
    },
    {
        id: 2,
        heading: 'Meyani POS Systems',
        img: img2,
        content: 'Our Meyani Point of Sale (POS) systems are designed to streamline retail and business transactions. With features like inventory management, sales tracking, and customer analytics, our POS systems empower businesses to operate efficiently. Easy to use and scalable, Meyani’s POS solutions are perfect for small businesses, large enterprises, and everything in between, ensuring a seamless experience for both business owners and customers.'
    },
]

export default fakeData;
