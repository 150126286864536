import React from "react";
import styles from './Middle.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faCashRegister, faCloud } from '@fortawesome/free-solid-svg-icons'; // Import relevant icons

const Middle = () => {
    return (
        <div style={{backgroundColor: '#f8f8f8'}} className='overflow-hidden'>
            <div className={`container ${styles.contain}`}>
                {/* Main heading for the section */}
                <p data-aos='fade-down-right' data-aos-offset="170" className={styles.mainHead}>
                    Discover Innovative Tech Solutions with Meyani
                </p>
                <p data-aos='fade-up-left' data-aos-offset="170" className={styles.mainContent}>
                    At Meyani, we are committed to delivering cutting-edge software solutions that transform businesses. Whether you're looking for custom-built applications, seamless Point of Sale systems, or cloud-based services, our team of experts is here to help. With our innovative approach and dedication to excellence, Meyani brings advanced technology and software products to your business.
                </p>

                <div className="row">
                    {/* First product/service block */}
                    <div data-aos='fade-right' data-aos-offset="170" className="col-md-4">
                        <div className={styles.box}>
                            <FontAwesomeIcon icon={faCode} size="4x" className={styles.icon}/> {/* Replaced image with FontAwesomeIcon */}
                            <p className={styles.head}>Custom Software Development</p>
                            <p className={styles.content}>
                                We create tailored software solutions to meet your business needs, from web and mobile applications to enterprise-level software.
                            </p>
                        </div>
                    </div>

                    {/* Second product/service block */}
                    <div data-aos='fade-up' className="col-md-4">
                        <div className={styles.box}>
                            <FontAwesomeIcon icon={faCashRegister} size="4x" className={styles.icon}/> {/* Replaced image with FontAwesomeIcon */}
                            <p className={styles.head}>Meyani POS Systems</p>
                            <p className={styles.content}>
                                Our advanced Point of Sale systems help businesses streamline transactions, manage inventory, and track sales, all in one easy-to-use platform.
                            </p>
                        </div>
                    </div>

                    {/* Third product/service block */}
                    <div data-aos='fade-left' className="col-md-4">
                        <div className={styles.box}>
                            <FontAwesomeIcon icon={faCloud} size="4x" className={styles.icon}/> {/* Replaced image with FontAwesomeIcon */}
                            <p className={styles.head}>Cloud Services</p>
                            <p className={styles.content}>
                                Scale your business with our cloud solutions. We provide reliable cloud infrastructure that allows you to store, manage, and access data securely.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Middle;
