
export const fakeData = [
    {
        id: 1,
        img: 'https://via.placeholder.com/150', 
        name: 'Trizah Alakonya',
        profession: 'Owner, Trizah Boutique',
        message: "Meyani worked on my website and it is incredibly responsive. Their team truly understood my vision and delivered a stunning result that exceeds my expectations!"    },
    {
        id: 2,
        img: 'https://via.placeholder.com/150', 
        name: 'Nelson Tommogo',
        profession: 'CEO, MEBIUT',
        message: "Meyani helped us develop a website that transformed our online presence. It’s user-friendly and responsive, allowing us to engage with our community more effectively."    },
    {
        id: 3,
        img: 'https://via.placeholder.com/150',
        name: 'Lavendar Akinyi',
        profession: 'Senior UI/UX Designer',
        message: "Thanks to Meyani, our new website has a clean design and is fully responsive. Their team listened to our needs and crafted a perfect solution!"    },
    {
        id: 4,
        img: 'https://via.placeholder.com/150', 
        name: 'Alan Wekesa',
        profession: 'Sofware Engineer, One Health',
        message: "Meyani’s tech expertise helped us build a robust platform for our healthcare services."
    },
    {
        id: 5,
        img: 'https://via.placeholder.com/150',
        name: 'Tony Wekesa',
        profession: 'Economist & Data Analyst',
        message: "Meyani’s data analytics services have given us invaluable insights into our customer behaviors."
    },
    {
        id: 6,
        img: 'https://via.placeholder.com/150',
        name: 'Musa Mona',
        profession: 'Tech Entrepreneur',
        message: "I’ve worked with Meyani on several projects, and their ability to deliver innovative solutions is unmatched."
    },
];
