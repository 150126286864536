import img1 from '../../assets/caseStudy/k1.jpeg';
import img2 from '../../assets/caseStudy/case4.jpeg';
import img3 from '../../assets/caseStudy/k2.jpeg';
import img4 from '../../assets/caseStudy/k3.jpeg';

const fakeData = [
    {
        id: 1,
        designation: 'Web Development, Frontend',
        title: 'Creating Responsive Websites',
        img: img1,
        category: 'WEB DEVELOPMENT'
    },
    {
        id: 2,
        designation: 'Web Development, Backend',
        title: 'Building Scalable APIs',
        img: img2,
        category: 'WEB DEVELOPMENT'
    },
    {
        id: 3,
        designation: 'Mobile Apps, UX Design',
        title: 'Innovative Mobile Solutions',
        img: img3,
        category: 'MOBILE APPS'
    },
    {
        id: 4,
        designation: 'Mobile Apps, Cross-Platform',
        title: 'Developing Native Mobile Experiences',
        img: img4,
        category: 'MOBILE'
    },
    {
        id: 5,
        designation: 'Data Science, Machine Learning',
        title: 'Predictive Analytics for Business Growth',
        img: img1,
        category: 'DATA SCIENCE'
    },
    {
        id: 6,
        designation: 'Blockchain, FinTech',
        title: 'Decentralized Finance Solutions',
        img: img2,
        category: 'BLOCKCHAIN'
    }
];

export default fakeData;
