import React, { useState, useEffect } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import styles from "./NavbarMain.module.css";
import { NavLink } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa"; 
import logo from './logo.png'; 

const NavbarMain = () => {
  const [activeNav, setActiveNav] = useState([true, false, false, false, false, false]);
  const [expand, setExpand] = useState(false);

  const closeNav = () => {
    setExpand(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem("NavbarMain") != null) {
      let temp = JSON.parse(sessionStorage.getItem("NavbarMain"));
      setActiveNav([...temp]);
    }
  }, []);

  const handleActiveNav = (i) => {
    let temp = activeNav.map(() => false);
    temp[i] = true;
    setActiveNav([...temp]);
    sessionStorage.setItem("NavbarMain", JSON.stringify(temp));
  };

  return (
    <>
      <Navbar
        style={{
          backgroundColor: "white",
          boxShadow: "1px 1px 10px rgb(0 0 0 / 0.4)",
        }}
        variant="light"
        expand="lg"
        sticky="top"
        onToggle={() => {
          setExpand((prevState) => !prevState);
        }}
        expanded={expand}
      >
        <Container>
          <Navbar.Brand href="/" className={styles.logo}>
            <img src={logo} alt="Mebiut Logo" style={{ width: '140px', height: 'auto', marginRight: '8px' }} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
              <NavLink
                to="/"
                className={`${styles.nav_text} nav-link ${activeNav[0] ? styles.active : ""}`}
                style={{ marginTop: "8px", paddingLeft: '16px' }}
                onClick={() => {
                  handleActiveNav(0);
                  closeNav();
                }}
              >
                Home
              </NavLink>

              {/* Services Link (Dropdown part commented out for now) */}
              <NavLink
                to="/services"
                className={`${styles.nav_text} nav-link ${activeNav[1] ? styles.active : ""}`}
                style={{ marginTop: "8px", paddingLeft: '16px' }}
                onClick={() => {
                  handleActiveNav(1);
                  closeNav();
                }}
              >
                Services
              </NavLink>

              {/* Services Dropdown - COMMENTED OUT for future use */}
              {/* <NavDropdown
                title={
                  <span className={`${styles.nav_text} nav-link`}>
                    Services <FaAngleDown />
                  </span>
                }
                id="services-dropdown"
                align="end"
                style={{ marginTop: "8px" }}
              >
                <NavDropdown.Item as={NavLink} to="/services">
                  Services Overview
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/customSoftware">
                  Custom Software Development
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/webDevelopment">
                  Web Development
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/dataAnalytics">
                  Data Analytics
                </NavDropdown.Item>
              </NavDropdown> */}

              {/* About Us */}
              <NavLink
                to="/aboutUs"
                className={`${styles.nav_text} nav-link ${activeNav[2] ? styles.active : ""}`}
                style={{ marginTop: "8px", paddingLeft: '16px' }}  // Remove space between "Services" and "About Us"
                onClick={() => {
                  handleActiveNav(2);
                  closeNav();
                }}
              >
                About Us
              </NavLink>

              {/* Shop */}
              <NavLink
                to="/shop"
                className={`${styles.nav_text} nav-link ${activeNav[3] ? styles.active : ""}`}
                style={{ marginTop: "8px", paddingLeft: '16px' }}
                onClick={() => {
                  handleActiveNav(3);
                  closeNav();
                }}
              >
                Shop
              </NavLink>

              {/* More Insights Dropdown */}
              <NavDropdown
                title={
                  <span className={`${styles.nav_text} nav-link`}>
                    More Insights <FaAngleDown />
                  </span>
                }
                id="more-insights-dropdown"
                align="end"
                style={{ marginTop: "8px" }}
              >
                <NavDropdown.Item as={NavLink} to="/blog">
                  Blog
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/team">
                  Team
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/contactUs">
                  Contact Us
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarMain;
