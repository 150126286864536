import { faCode, faServer, faChartLine, faMobileAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';

const data = [
    {
        id: 1,
        heading: 'Custom Software Development',
        content: 'Partner with our expert developers to create tailored software solutions for your business needs, improving efficiency and scalability.',
        icon: faCode, // Icon for software development
    },
    {
        id: 2,
        heading: 'Cloud Infrastructure',
        content: 'Leverage the power of cloud technology to optimize your IT infrastructure, offering flexibility, security, and scalability for your enterprise.',
        icon: faServer, // Icon representing cloud services and infrastructure
    },
    {
        id: 3,
        heading: 'Data Analytics & Insights',
        content: 'Unlock valuable insights with our advanced data analytics solutions, helping your business make data-driven decisions to stay competitive.',
        icon: faChartLine, // Icon representing data and analytics
    },
    {
        id: 4,
        heading: 'Mobile App Development',
        content: 'Design and develop innovative mobile applications that enhance customer engagement and provide seamless experiences across platforms.',
        icon: faMobileAlt, // Icon for mobile app development
    },
    {
        id: 5,
        heading: 'Web Development & Design',
        content: 'Build responsive and visually appealing websites that drive engagement, optimize user experience, and expand your digital presence globally.',
        icon: faGlobe, // Icon for web development and global reach
    },
    {
        id: 6,
        heading: 'Point of Sale (POS) Systems',
        content: 'Implement advanced POS systems to streamline your sales processes and improve customer service.',
        icon: faServer, // You can choose a different icon if needed
    },
    {
        id: 7,
        heading: 'Operating Systems',
        content: 'Expertise in various operating systems including Windows, Linux Distros, and more to meet your technical needs.',
        icon: faCode, // You can choose a different icon if needed
    },
    {
        id: 8,
        heading: 'Hosting Services',
        content: 'Reliable hosting solutions that ensure your website is always up and running, with optimal performance and security.',
        icon: faServer, // You can choose a different icon if needed
    },
];

export default data;
