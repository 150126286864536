export const blogs = [
  {
    id: 1,
    title: 'The Future of AI in Tech',
    content: 'Artificial Intelligence (AI) is transforming industries across the world...',
    author: 'Bright',
    date: 'October 10, 2024',
    category: 'Artificial Intelligence',
    image: 'https://images.unsplash.com/photo-1518770660439-4636190af475',
    likes: 0,
  },
  {
    id: 2,
    title: 'Why Startups Should Focus on Sustainability',
    content: 'Sustainability is no longer just a buzzword...',
    author: 'Jane Smith',
    date: 'September 25, 2024',
    category: 'Sustainability',
    image: 'https://images.unsplash.com/photo-1542831371-d531d36971e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDI2fHxzdXN0YWluYWJpbGl0eXxlbnwwfHx8fDE2NjQzNzc3MTI&ixlib=rb-1.2.1&q=80&w=1080',
    likes: 0,
  },
  {
    id: 3,
    title: 'Cloud Computing: The Backbone of Modern Infrastructure',
    content: 'Cloud computing has become essential in managing data and infrastructure...',
    author: 'Alex Brown',
    date: 'August 30, 2024',
    category: 'Cloud Computing',
    image: 'https://images.unsplash.com/photo-1484417894907-623942c8ee29',
    likes: 0,
  },
  {
    id: 4,
    title: '5G Networks: What’s Next?',
    content: '5G networks promise faster speeds and improved connectivity...',
    author: 'Lisa Ray',
    date: 'July 15, 2024',
    category: 'Telecommunications',
    image: 'https://images.unsplash.com/photo-1503676260728-1c00da094a0b',
    likes: 0,
  },
  {
    id: 10,
    title: 'Autonomous Vehicles: A Glimpse Into the Future',
    content: 'The development of autonomous vehicles (AVs) has the potential to revolutionize transportation...',
    author: 'Robert Moore',
    date: 'January 22, 2024',
    category: 'Autonomous Vehicles',
    image: 'https://images.unsplash.com/photo-1525609004556-c46c7d6cf023',
    likes: 0,
  },
];

export default blogs;
