import React from 'react';
import styles from './Bottom.module.css';
import fakeData from './fakeData';

const Bottom = () => {
    return (
        <>
            <div className={`container ${styles.contain} overflow-hidden`}>
                {/* Headings in a single row */}
                <div className="row">
                    {fakeData.map((x) => (
                        <div key={x.id} className="col-md-6">
                            <p className={styles.head}>{x.heading}</p>
                        </div>
                    ))}
                </div>

                {/* Images and content below the headings */}
                <div className="row">
                    {fakeData.map((x) => (
                        <div key={x.id} className="col-md-6">
                            <img src={x.img} alt={x.heading} className={styles.img} />
                            <p className={styles.content}>{x.content}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Bottom;
