import React from "react";
import styles from './ChoosingUs.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faShieldAlt, faHandshake } from '@fortawesome/free-solid-svg-icons'; // Updated icons

const ChoosingUs = () => {
    return (
        <div style={{ backgroundColor: '#e5f3ff' }}>
            <div className="container overflow-hidden">
                <p data-aos='slide-right' data-aos-offset="220" className={styles.heading}>Why Meyani Technology???</p>
                <p data-aos='slide-left' className={styles.sub_text}></p>
                <div className={`row ${styles.sub}`}>
                    <div data-aos='fade-right' className="col-md-4 text-center">
                        <FontAwesomeIcon icon={faLaptopCode} size="3x" className={styles.icon} />
                        <p className={styles.logo_head}>Innovative Tech Solutions</p>
                        <p className={styles.logo_text}>We use the latest technology to create scalable solutions that empower businesses to thrive in the digital world.</p>
                    </div>

                    <div data-aos='fade' className="col-md-4 text-center">
                        <FontAwesomeIcon icon={faShieldAlt} size="3x" className={styles.icon} />
                        <p className={styles.logo_head}>Security and Reliability</p>
                        <p className={styles.logo_text}>We build secure, reliable systems that protect your data and ensure seamless business operations.</p>
                    </div>

                    <div data-aos='fade-left' className="col-md-4 text-center">
                        <FontAwesomeIcon icon={faHandshake} size="3x" className={styles.icon} />
                        <p className={styles.logo_head}>Client-Centric Collaboration</p>
                        <p className={styles.logo_text}>Our approach focuses on partnership, ensuring that we work closely with you to achieve your goals and drive innovation.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChoosingUs;
