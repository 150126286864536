import React from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import styles from './AboutUsHome.module.css';
import img1 from '../../assets/home/01.jpeg';  // Example tech-related images
import img2 from '../../assets/home/02.jpeg';

const AboutUsHome = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleReachUsClick = () => {
        navigate('/Team'); // Navigate to the ContactUs page
    };

    return (
        <div className={`container ${styles.contain}`}>
            <div className="row overflow-hidden">
                <div className="col-md-6">
                    <p data-aos="slide-right" className={styles.head}>WHO ARE WE??</p>
                    <p data-aos="slide-left" className={styles.heading}>We're Meyani</p>
                    <div data-aos="zoom-in">
                        <p className={styles.content}>
                            We're committed to providing innovative technology solutions that help businesses thrive in the digital world. Our team of experts specializes in custom software development, advanced POS systems, and cloud services, delivering solutions that are tailored to meet the unique needs of each business. Whether you're looking to streamline operations or scale with the cloud, Meyani has the expertise and technology to make it happen.
                        </p>
                    </div>
                </div>

                <div className="col-md-6 mt-5 overflow-hidden">
                    <div className={styles.row}>
                        {/* Updated to use only two images */}
                        <div className={`${styles.column} ${styles.col1}`}>
                            <img data-aos="zoom-in-right" src={img1} alt="Custom Software Solutions" />
                        </div>
                        <div className={styles.column}>
                            <img data-aos="zoom-in-left" src={img2} style={{ width: '110%' }} alt="Cloud Services" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Button below the images */}
            <div className="text-center mt-4">
                <button 
                    className={`btn custom_btn ${styles.btn}`} 
                    onClick={handleReachUsClick} 
                >
                    MEET THE TEAM
                </button>
            </div>
        </div>
    );
};

export default AboutUsHome;
