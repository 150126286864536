import { faCloud, faCode, faMobileAlt, faSearch, faServer, faUserShield } from '@fortawesome/free-solid-svg-icons';

const data = [
    {
        id: 1,
        heading: 'Custom Software Development',
        content: 'We offer tailor-made software development solutions designed to meet your business needs, ensuring seamless workflows and optimized operations.',
        to: '/',
        icon: faCode, // FontAwesome icon for coding/software development
        contents: [
            {
                heading: 'Streamlined Solutions',
                img: '/assets/individualService/software_1.png',
                content: 'Our team will develop custom software that caters to your specific business requirements, ensuring efficiency and scalability.'
            },
            {
                heading: 'Cutting-Edge Technology',
                img: '/assets/individualService/software_2.png',
                content: 'We utilize the latest technology to create software solutions that are future-proof and easy to integrate into existing systems.'
            },
        ]
    },
    {
        id: 2,
        heading: 'Cloud Infrastructure Services',
        content: 'With our cloud services, we help businesses transition smoothly to the cloud, providing flexibility, security, and scalability for your operations.',
        to: '/',
        icon: faCloud, // FontAwesome icon for cloud services
        contents: [
            {
                heading: 'Secure and Scalable',
                img: '/assets/individualService/cloud_1.png',
                content: 'Our cloud infrastructure services ensure that your data is securely stored, easily accessible, and scalable to meet growing demands.'
            },
            {
                heading: '24/7 Support',
                img: '/assets/individualService/cloud_2.png',
                content: 'We offer round-the-clock support to ensure your cloud operations run smoothly and without interruptions.'
            },
        ]
    },
    {
        id: 3,
        heading: 'Mobile App Development',
        content: 'We specialize in building innovative, user-friendly mobile applications tailored to your business, enabling you to connect with customers anywhere, anytime.',
        to: '/',
        icon: faMobileAlt, // FontAwesome icon for mobile apps
        contents: [
            {
                heading: 'Cross-Platform Expertise',
                img: '/assets/individualService/mobile_1.png',
                content: 'Our mobile apps are designed to run seamlessly on both Android and iOS platforms, ensuring maximum reach for your business.'
            },
            {
                heading: 'Custom Features',
                img: '/assets/individualService/mobile_2.png',
                content: 'We build mobile apps with features tailored to your business goals, such as e-commerce integration, user analytics, and push notifications.'
            },
        ]
    },
    {
        id: 4,
        heading: 'Cybersecurity Solutions',
        content: 'We provide comprehensive cybersecurity services to protect your business from data breaches, cyber threats, and unauthorized access.',
        to: '/',
        icon: faUserShield, // FontAwesome icon for security
        contents: [
            {
                heading: 'Advanced Threat Detection',
                img: '/assets/individualService/security_1.png',
                content: 'Our cybersecurity solutions offer real-time threat detection and response, ensuring your business stays protected 24/7.'
            },
            {
                heading: 'Data Encryption & Protection',
                img: '/assets/individualService/security_2.png',
                content: 'We provide robust encryption techniques and secure data storage to keep your sensitive information safe.'
            },
        ]
    },
    {
        id: 5,
        heading: 'Data Analytics & AI',
        content: 'We leverage advanced data analytics and artificial intelligence to help businesses make data-driven decisions that boost performance and productivity.',
        to: '/',
        icon: faSearch, // FontAwesome icon for data analytics/AI (representing search/analysis)
        contents: [
            {
                heading: 'AI-Powered Insights',
                img: '/assets/individualService/analytics_1.png',
                content: 'Our AI solutions analyze large datasets to extract valuable insights that can help you make informed business decisions.'
            },
            {
                heading: 'Data-Driven Strategies',
                img: '/assets/individualService/analytics_2.png',
                content: 'We provide analytics services to identify trends and improve operational efficiency, helping you stay ahead of the competition.'
            },
        ]
    },
    {
        id: 6,
        heading: 'Server & Networking Solutions',
        content: 'We design and manage reliable server and networking solutions to ensure that your business’s IT infrastructure runs smoothly and efficiently.',
        to: '/',
        icon: faServer, // FontAwesome icon for server and networking solutions
        contents: [
            {
                heading: 'Optimized Server Management',
                img: '/assets/individualService/server_1.png',
                content: 'Our server management services ensure your infrastructure remains robust, secure, and capable of handling heavy workloads.'
            },
            {
                heading: 'Reliable Network Solutions',
                img: '/assets/individualService/server_2.png',
                content: 'We provide networking solutions that guarantee uptime, speed, and security for all your business operations.'
            },
        ]
    }
];

export default data;
