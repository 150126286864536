import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from './Banner.module.css';

const images = [
  { src: require('../../assets/6.jpg'), alt: "Image 1", text: "Innovative Tech Solutions for Your Business", buttonLabel: "LEARN MORE", buttonLink: "/services" },
  { src: require('../../assets/om.jpeg'), alt: "Image 2", text: "Artificial Intelligence and Block Chain", buttonLabel: "EXPLORE CLOUD", buttonLink: "" },
  { src: require('../../assets/om0.jpeg'), alt: "Image 3", text: "POS Systems that Streamline Your Operations", buttonLabel: "VIEW QUOTATION", buttonLink: "/Quotation" },
  { src: require('../../assets/home2.jpg'), alt: "Image 4", text: "Custom Software Development for Growth", buttonLabel: "GET STARTED", buttonLink: "/contactUs" },
  { src: require('../../assets/10.jpeg'), alt: "Image 5", text: "Bringing the Future of Tech to Your Business", buttonLabel: "DISCOVER MORE", buttonLink: "/aboutUs" },
];

const PreviousArrow = ({ onClick }) => (
  <div className={styles.prevArrow} onClick={onClick}>
    <FontAwesomeIcon icon={faChevronLeft} size="2x" />
  </div>
);

const NextArrow = ({ onClick }) => (
  <div className={styles.nextArrow} onClick={onClick}>
    <FontAwesomeIcon icon={faChevronRight} size="2x" />
  </div>
);

const Banner = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleButtonClick = (link) => {
    if (link) {
      navigate(link);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, 
    beforeChange: (_current, next) => setCurrentSlide(next),
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div className={styles.imgbox}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image.src} alt={image.alt} className={styles.slideImage} />
          </div>
        ))}
      </Slider>
      <div className={`${styles.center}`}>
        <p data-aos='fade-down' className={styles.text}>{images[currentSlide].text}</p>
        <button
          data-aos='fade-up'
          className={`btn custom_btn ${styles.btn}`}
          onClick={() => handleButtonClick(images[currentSlide].buttonLink)}
        >
          {images[currentSlide].buttonLabel}
        </button>
      </div>
    </div>
  );
};

export default Banner;
