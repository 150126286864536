import React from "react";
import styles from './Top.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faCloud, faLightbulb } from '@fortawesome/free-solid-svg-icons'; // Importing relevant icons

const Top = () => {
    return (
        <div className={`container overflow-hidden`}>
            {/* Main heading for the section */}
            <p data-aos='slide-right' className={styles.heading}>About Meyani</p>
            <p data-aos='slide-left' className={styles.subHeading}>Innovative Tech Solutions for Modern Businesses</p>
            <p data-aos='fade-up' data-aos-offset='80' className={styles.content}>
                Welcome to <strong>Meyani</strong>, where we are dedicated to providing innovative technology solutions that empower businesses to achieve more. Our expertise spans across software development, POS systems, and cloud services, all designed to meet the unique needs of your business. Whether you're looking to streamline operations or scale with the cloud, Meyani is your trusted tech partner.
            </p>

            {/* Section with icon and description */}
            <div className={`row ${styles.contain} flex-column-reverse flex-md-row`}>
                <div data-aos='slide-right' className={`col-md-6`}>
                    <p className={styles.about}>Our Story</p>
                    <p className={styles.creativity}>Driving Innovation with Expertise</p>
                    <p className={styles.contn}>
                        At Meyani, we started with a vision to transform businesses through technology. Our journey has been marked by a commitment to creating software that addresses real-world challenges, helping companies streamline their operations, improve customer engagement, and optimize their workflows.
                        <br/><br/>
                        Whether it's developing custom software, implementing advanced POS systems, or providing cloud-based solutions, we pride ourselves on delivering top-notch products and services that cater to the evolving needs of businesses globally. <strong>Meyani</strong> is more than just a tech company—it’s a partner in your business’s growth.
                        <br/><br/>
                        Explore our range of services, from custom software development to cloud solutions, and see how we can help your business thrive in the digital era. With Meyani, innovation is at your fingertips.
                    </p>
                </div>

                {/* Icon section for services or products */}
                <div data-aos='slide-left' data-aos-offset='50' className={`col-md-6 ${styles.iconContain}`}>
                    <FontAwesomeIcon icon={faCode} size="7x" className={`${styles.icon} ${styles.iconBlue}`} /> {/* Custom Software Development */}
                </div>
            </div>

            {/* Additional icon or highlight */}
            <div data-aos='fade-up' className='row' style={{textAlign: 'left'}}>
                <div className={`col-md-6`}>
                    <FontAwesomeIcon icon={faCloud} size="7x" className={`${styles.icon} ${styles.iconBlue}`} /> {/* Cloud Services */}
                </div>
                <div className={`col-md-6`}>
                    <FontAwesomeIcon icon={faLightbulb} size="7x" className={`${styles.icon} ${styles.iconBlue} ${styles.bulbEffect}`} /> {/* Innovation with glow effect */}
                </div>
            </div>
        </div>
    );
}

export default Top;
