// Import the required images
import image1 from '../../assets/home/r1.jpeg';
import image2 from '../../assets/home/r2.jpeg';
import image3 from '../../assets/home/r3.jpeg';
import image4 from '../../assets/home/r4.jpeg';
import image5 from '../../assets/home/r5.jpeg';
import image6 from '../../assets/home/r6.jpeg';

const data = [
    {
        id: 1,
        heading: 'Custom Software Development',
        content: 'We offer tailored software development services to create unique, scalable solutions that meet the needs of your business, including custom enterprise systems.',
        contents: [
            {
                heading: 'Personalized Software Solutions',
                icon: image1, 
                content: 'Collaborate with our team to design software tailored to your specific business needs, whether it’s web, mobile, or desktop applications.'
            },
            {
                heading: 'Cutting-Edge Technologies',
                icon: image2, 
                content: 'We utilize the latest technology stacks to ensure your software is future-proof, reliable, and scalable to handle business growth.'
            },
        ]
    },
    {
        id: 2,
        heading: 'Cloud Services & Solutions',
        content: 'Leverage the power of the cloud with our cloud computing services, providing businesses with scalable and secure infrastructure solutions.',
        contents: [
            {
                heading: 'Cloud Infrastructure Management',
                icon: image3, 
                content: 'Optimize your infrastructure with our cloud services, ensuring seamless performance and scalability for your growing tech needs.'
            },
            {
                heading: 'Secure Data Storage',
                icon: image4, 
                content: 'Benefit from secure cloud-based storage solutions that provide high levels of encryption and availability for your business-critical data.'
            },
        ]
    },
    {
        id: 3,
        heading: 'Enterprise Solutions',
        content: 'We specialize in enterprise-level software solutions for large-scale businesses, helping streamline operations and improve productivity.',
        contents: [
            {
                heading: 'Custom ERP Systems',
                icon: image5, 
                content: 'Design and implement custom ERP systems tailored to your enterprise’s needs, improving workflow efficiency across departments.'
            },
            {
                heading: 'Integrated Solutions',
                icon: image6, 
                content: 'Develop software that integrates seamlessly with your existing systems, providing a unified platform for data and operations management.'
            },
        ]
    },
    {
        id: 4,
        heading: 'Training & Workshops',
        content: 'Join our hands-on technical training workshops to upskill your team and stay ahead with the latest technological advancements.',
        contents: [
            {
                heading: 'Hands-On Technical Training',
                icon: image1, 
                content: 'Participate in workshops led by industry experts to gain practical experience in software development, cloud technologies, and more.'
            },
            {
                heading: 'Expert Guidance',
                icon: image2, 
                content: 'Our seasoned professionals provide guidance to help your team stay current with the latest trends and technologies in the industry.'
            },
        ]
    },
    {
        id: 5,
        heading: 'IT Support & Managed Services',
        content: 'We offer comprehensive IT support and managed services to ensure the smooth operation of your tech infrastructure, from servers to cloud applications.',
        contents: [
            {
                heading: '24/7 IT Support',
                icon: image3, 
                content: 'Our team provides round-the-clock support to address any issues or downtime, ensuring your systems are always running optimally.'
            },
            {
                heading: 'Scalable Solutions',
                icon: image4, 
                content: 'Our managed services provide scalable solutions that grow with your business, ensuring you’re always prepared for future demands.'
            },
        ]
    }
];

export default data;
